import CircularProgress, { type CircularProgressProps } from '@mui/material/CircularProgress'

type Props = CircularProgressProps

export default function LoadingCenter(props: Props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress {...props} />
    </div>
  )
}

export function Loading(props: Props) {
  return <CircularProgress {...props} />
}
