import { getAPIAccessToken as getAPIAccessTokenBase } from 'client-shared/lib/auth0'
import { auth0 } from '../constants'

type ArgsType = Parameters<typeof getAPIAccessTokenBase>
export type GetAccessTokenSilently = ArgsType[0]
export type GetAccessTokenWithPopup = ArgsType[1]

export const getAPIAccessToken = async (
  getAccessTokenSilently: GetAccessTokenSilently,
  getAccessTokenWithPopup: GetAccessTokenWithPopup
) => {
  return await getAPIAccessTokenBase(getAccessTokenSilently, getAccessTokenWithPopup, {
    authorizationParams: {
      audience: auth0.authorizationParams.audience
    }
  })
}
