import type React from 'react'
import { useState, useMemo, useEffect } from 'react'
import { format } from 'date-fns'
import { Paper, Box, Stack, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos, ExpandMore } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import { useActcast } from '../../hooks/useActcast'

type Props = {
  deviceId: string
}

const Circle = styled('div')<{ checked: boolean }>(({ theme, checked }) => {
  return {
    width: '5px',
    height: '5px',
    borderRadius: '50%',
    backgroundColor: checked ? theme.palette.primary.main : theme.palette.background.default,
    transform: checked ? 'scale(1.5)' : 'scale(1)'
  }
})

const SlidePhoto: React.FC<Props> = (props) => {
  const {
    state: { listPhotoMap },
    fetchListPhoto
  } = useActcast()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (props.deviceId !== '') {
      fetchListPhoto(props.deviceId)
    }
  }, [fetchListPhoto, props.deviceId])

  const listPhoto = useMemo(() => {
    const list = listPhotoMap[props.deviceId]
    if (!list) {
      return []
    }
    return list
  }, [listPhotoMap, props.deviceId])

  const prev = () => setIndex(index - 1 > 0 ? (index - 1) % listPhoto.length : listPhoto.length - 1)
  const next = () => setIndex((index + 1) % listPhoto.length)

  if (!listPhoto) {
    return <></>
  }
  const photo = listPhoto[index]
  if (!photo) {
    return <></>
  }

  const time = format(new Date(photo.taken_at), 'yyyy/MM/dd HH:mm:ss')

  return (
    <Paper elevation={0} sx={{ width: '100%' }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>前後の画像をみる</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box component="img" src={photo.url} sx={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          <Stack direction="row">
            <Button startIcon={<ArrowBackIosNew />} onClick={prev}>
              進む
            </Button>
            <Stack sx={{ flex: 1 }} padding={1}>
              <Typography variant="body1" gutterBottom textAlign="center">
                {time}
              </Typography>
              <Stack direction="row" justifyContent="center" spacing={1}>
                {listPhoto.map((e, i) => {
                  return <Circle key={i} checked={i === index} />
                })}
              </Stack>
            </Stack>
            <Button endIcon={<ArrowForwardIos />} onClick={next}>
              戻る
            </Button>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Paper>
  )
}
export default SlidePhoto
