import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useDeviceAndDeviceGroups } from '../../hooks/useDeviceAndDeviceGroups'

export default function Organizations() {
  const { currentActcastDeviceGroupId, actcastDeviceGroups, setCurrentActcastDeviceGroupId } =
    useDeviceAndDeviceGroups()

  const menuItems = actcastDeviceGroups.ids.map((id) => {
    const group = actcastDeviceGroups.data[id]
    return (
      <MenuItem key={group._id} value={group._id}>
        {group.name}
      </MenuItem>
    )
  })

  const handleChange = (e: SelectChangeEvent) => {
    setCurrentActcastDeviceGroupId(e.target.value)
  }

  return (
    <Box>
      <FormControl fullWidth size="small">
        <Select value={currentActcastDeviceGroupId} onChange={handleChange}>
          {menuItems}
        </Select>
      </FormControl>
    </Box>
  )
}
