import styles from './index.module.css'

export default function Privacy() {
  return (
    <div className={styles.wrap}>
      <div className={styles.c12}>
        <h1 className={styles.c4}>
          <span className={styles.c8}>プライバシーポリシー</span>
        </h1>
        <h2 className={styles.c7}>
          <span className={styles.c2}>1.基本方針</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            Visnu株式会社（以下、当社）は、当社が取得した個人情報の取扱いに関し、個人情報の保護に関する法律、個人情報保護に関するガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>2.個人情報の安全管理</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、個人情報の保護に関して、組織的、物理的、人的、技術的に適切な対策を実施し、当社の取り扱う個人情報の漏えい、滅失又はき損の防止その他の個人情報の安全管理のために必要かつ適切な措置を講ずるものとします。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>3.個人情報の取得等の遵守事項</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>当社による個人情報の取得、利用、提供については、以下の事項を遵守します</span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h3 className={styles.c11}>
          <span className={styles.c5}>(1) 個人情報の取得</span>
        </h3>
        <p className={styles.c3}>
          <span className={styles.c0}>当社は例として以下のような個人情報を収集します。</span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社の運営する Webサイトに寄せられたお問い合わせ等、当社へのご連絡に含まれる情報
          </span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>当社の提供するサービスのご契約・ご利用時にご提供頂いた情報</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>その他、当社とのご契約締結の際に頂いた情報</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>当社の採用活動・主催するイベントに参加された方から頂いた情報</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>当社の社員と名刺交換をしていただいた際に頂いた名刺に記載された情報</span>
        </p>
        <h3 className={styles.c11}>
          <span className={styles.c5}>(2) 個人情報の利用目的</span>
        </h3>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、当社が取得した個人情報について、法令に定める場合又は本人の同意を得た場合を除き、以下に定める利用目的の達成に必要な範囲を超えて利用することはありません。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>弊社提供事業運営のためのご連絡やご請求等、必要な業務上の手続き</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>
            上記運営のための目的のものを除いた、弊社運営事業に関するメールおよび郵送でのご連絡
          </span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>パートナー企業の方に対する業務提携等のご相談</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>弊社提供サービスの品質向上のためのアンケートの送付</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>弊社提供サービスのマーケティング業務</span>
        </p>
        <p className={styles.c3}>
          <span className={styles.c0}>採用活動に関するご連絡、自社社員管理業務等の人事関連業務</span>
        </p>
        <h3 className={styles.c11}>
          <span className={styles.c5}>(3) 個人情報の提供等</span>
        </h3>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、法令で定める場合を除き、本人の同意に基づき取得した個人情報を、本人の事前の同意なく第三者に提供することはありません。なお、本人の求めによる個人情報の開示、訂正、追加若しくは削除又は利用目的の通知については、法令に従いこれを行うとともに、ご意見、ご相談に関して適切に対応します。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>4.個人情報の利用目的の変更</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、前項で特定した利用目的は、あらかじめ本人の同意を得た場合を除くほかは、原則として変更しません。ただし、変更前の利用目的と関連性を有すると合理的に認められる範囲において、あらかじめ変更後の利用目的を公表の上で変更を行う場合はこの限りではありません。変更内容の公表は当ホームページにて行います。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>5.個人情報の第三者提供</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、お客様からご提供いただいた個人情報を、第三者に提供することはありません。また、今後第三者提供を行う事になった場合には、提供する情報と提供目的などを提示し、お客様から同意を得た場合のみ第三者提供を行います。また、当社では、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>6.個人情報の取扱いの改善・見直し</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、個人情報の取扱い、管理体制及び取組みに関する点検を実施し、継続的に改善・見直しを行います。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>7.個人情報の廃棄</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c0}>
            当社は、個人情報の利用目的に照らしその必要性が失われたときは、個人情報を消去又は廃棄するものとし、当該消去及び廃棄は、外部流失等の危険を防止するために必要かつ適切な方法により、業務の遂行上必要な限りにおいて行います。
          </span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
        <h2 className={styles.c7}>
          <span className={styles.c2}>8.お問い合わせ窓口</span>
        </h2>
        <p className={styles.c3}>
          <span className={styles.c10}>
            <a
              className={styles.c9}
              href="https://www.google.com/url?q=https://visnu.jp/%23contact&amp;sa=D&amp;source=editors&amp;ust=1691387406978960&amp;usg=AOvVaw2_dr69EtOWrRUkAo0KCa6y"
            >
              こちら
            </a>
          </span>
          <span className={styles.c0}>からお願いいたします。</span>
        </p>
        <p className={styles.c1}>
          <span className={styles.c0}></span>
        </p>
      </div>
    </div>
  )
}
