import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'
import { clients } from '../lib/clients'
import { getAPIAccessToken } from '../lib/auth0'
import { actcastDeviceGroupsState } from './recoil/deviceGrups'
import { useFetchDevicesFromGroupsBase } from './recoil/devices'

type Role = {
  loading: boolean
  systemAdmin: boolean
}

const roleState = atom<Role>({
  key: 'myProfile:role',
  default: {
    loading: true,
    systemAdmin: false
  }
})

export const useMyProfile = () => {
  const [role] = useRecoilState(roleState)

  return {
    role
  } as const
}

export const useMyProfileForRoot = () => {
  const [role, setRole] = useRecoilState(roleState)
  const [, setActcastDeviceGroups] = useRecoilState(actcastDeviceGroupsState)
  const { fetchDevicesFromGroupsBase } = useFetchDevicesFromGroupsBase()
  const { getAccessTokenSilently, getAccessTokenWithPopup, user } = useAuth0()

  const updateMyProfile = useCallback(async () => {
    if (!user) {
      return
    }
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    return await clients['/api/@me/profile'].POST.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }, [getAccessTokenSilently, getAccessTokenWithPopup, user])

  const fetchMyProfile = useCallback(async () => {
    if (!user) {
      return
    }
    const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    return await clients['/api/@me'].GET.client({
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }, [getAccessTokenSilently, getAccessTokenWithPopup, user])

  const initMyProfile = useCallback(async () => {
    setRole({
      loading: true,
      systemAdmin: false
    })
    const res = await fetchMyProfile()
    setRole({
      loading: false,
      systemAdmin: res.status === 200 ? res.body.roles.includes('system-admin') : false
    })
    if (!res) {
      return
    }
    if (res.status === 404) {
      updateMyProfile()
    }
  }, [fetchMyProfile, setRole, updateMyProfile])

  const initMyActcastDeviceGroups = useCallback(async () => {
    if (!user) {
      return
    }
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    setActcastDeviceGroups((current) => {
      return { ...current, loading: true }
    })
    const res = await clients['/api/@me/actcastDeviceGroups'].GET.client({
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    if (!res) {
      return
    }
    const ids = []
    const data = {}
    for (const group of res.body.actcastDeviceGroups) {
      ids.push(group._id)
      data[group._id] = group
    }
    const actcastDeviceGroupId = ids[0] ?? ''
    const devices = await fetchDevicesFromGroupsBase({ actcastDeviceGroupId, accessToken })

    setActcastDeviceGroups((current) => {
      return {
        ...current,
        initializedFlag: true,
        loading: false,
        currentActcastDeviceGroupId: actcastDeviceGroupId,
        actcastDeviceGroups: { ids, data }
      }
    })

    return devices
  }, [fetchDevicesFromGroupsBase, getAccessTokenSilently, getAccessTokenWithPopup, setActcastDeviceGroups, user])

  return {
    initMyProfile,
    role,
    initMyActcastDeviceGroups
  } as const
}
