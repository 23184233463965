import { type PropsWithChildren } from 'react'
import Box from '@mui/material/Box'

export default function Layout(props: PropsWithChildren) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {props.children}
    </Box>
  )
}
