import type { PropsWithChildren, FC } from 'react'
import styled from '@emotion/styled'
import { Avatar, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useActcast } from '../../hooks/useActcast'
import { useDevicePhotoViewer } from '../../hooks/useDevicePhotoViewer'

type Device = ReturnType<typeof useActcast>['state']['devices']['devices'][string]

export default function DeviceListItem({ data }: { data: Device }) {
  const {
    state: { latestPhotoMap: latestPhotos },
    fetchLatestPhoto
  } = useActcast()
  const { openDialog } = useDevicePhotoViewer()

  useEffect(() => {
    if (data.actcastId) {
      fetchLatestPhoto(data.actcastId)
    }
  }, [data.actcastId, fetchLatestPhoto])

  const photoData = useMemo(() => {
    if (data == null) return
    const _photoData = latestPhotos[data.actcastId]
    return _photoData
  }, [data, latestPhotos])

  return (
    <ListItemButton onClick={() => openDialog(data)}>
      <ListItemAvatar sx={{ minWidth: 100 }}>
        <Avatar src={photoData?.url} variant="rounded" sx={{ width: 80, height: 80 }}></Avatar>
      </ListItemAvatar>
      <ListItemText>
        <Dl>
          <Dt>ID:</Dt>
          <Dd>{data?._id}</Dd>
          <Dt>Name:</Dt>
          <Dd>{data?.name}</Dd>
          <Dt>Foundness:</Dt>
          <Dd>{data?.foundness}</Dd>
        </Dl>
      </ListItemText>
    </ListItemButton>
  )
}

const Dt: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="caption"
      component="dt"
      sx={{
        flexBasis: '25%',
        paddingTop: '4px',
        paddingBottom: '4px'
      }}
    >
      {children}
    </Typography>
  )
}

const Dd: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Typography
      variant="caption"
      component="dd"
      sx={{
        flexBasis: '75%',
        marginInlineStart: '0px',
        paddingTop: '4px',
        paddingBottom: '4px'
      }}
    >
      {children}
    </Typography>
  )
}

const Dl = styled.dl(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  margin: '0px'
}))
