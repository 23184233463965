import { useState, useEffect, useMemo } from 'react'
import { useStorage, setStorage } from '@rpgtec/use-storage'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

const key = `confirm-${Date.now()}`

export default function confirm(options = {}) {
  return new Promise((resolve) => setStorage(key, { ...options, resolve }))
}

export function Confirm(defaultProps) {
  const [open, setOpen] = useState(false)
  const [state, setState] = useStorage(key, null)

  useEffect(() => {
    if (state) {
      setOpen(true)
    }
  }, [state])

  const { resolve, ...options } = state || {}
  const { title, content, cancelText, confirmText, ...dialogProps } = useMemo(() => {
    return {
      title: '本当によろしいですか？',
      cotnent: null,
      confirmText: 'OK',
      cancelText: '閉じる',
      ...options
    }
  }, [options])

  const onClose = () => {
    resolve(null)
    setOpen(false)
  }

  const onCancel = () => {
    resolve(false)
    setOpen(false)
  }

  const onConfirm = () => {
    resolve(true)
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionProps={{ onExited: () => setState(null) }}
      fullWidth
      maxWidth="xs"
      {...defaultProps}
      {...dialogProps}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && (
        <DialogContent>
          {typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel}>{cancelText}</Button>
        <Button onClick={onConfirm}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  )
}
