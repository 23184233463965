import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

export default function SettingPage() {
  return (
    <Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  )
}
