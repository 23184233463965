import Box from '@mui/material/Box'
import { useNotifications, type NotificationState } from '../../hooks/useNotifications'

export default function NotificationList() {
  const { notifications } = useNotifications()

  return (
    <Box>
      <Notifications notifications={notifications.notifications} />
    </Box>
  )
}

function Notifications(props: { notifications: NotificationState['notifications'] }) {
  if (props.notifications.ids.length === 0) {
    return <EmptyItem />
  }

  return (
    <Box>
      {props.notifications.ids.map((id) => {
        const notification = props.notifications.data[id]
        return <NotificationItem key={id} notification={notification} />
      })}
    </Box>
  )
}

function NotificationItem(props: { notification: NotificationState['notifications']['data'][string] }) {
  return <Box sx={{ p: 3, borderBottom: '1px solid' }}>{props.notification.message}</Box>
}

function EmptyItem() {
  return <Box sx={{ p: 3, borderBottom: '1px solid' }}>通知はありません</Box>
}
