import type { LatestPhotoType, RequestTakePhotoStatus } from '../../hooks/useActcast'
import type { DevicePhotoDialogData } from '../../hooks/useDevicePhotoViewer'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import { LoadingButton } from '@mui/lab'
import SlidePhoto from './SlidePhoto'

const Dl = styled.dl(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  margin: '0px'
}))

const Dt = styled.dt(() => ({
  flexBasis: '20%',
  paddingTop: '4px',
  paddingBottom: '4px'
}))

const Dd = styled.dd(() => ({
  flexBasis: '80%',
  marginInlineStart: '0px',
  paddingTop: '4px',
  paddingBottom: '4px'
}))

type Props = {
  open: boolean
  photoData: LatestPhotoType
  requestTakePhotoStatus: RequestTakePhotoStatus
  devicePhotoDialog: DevicePhotoDialogData
  onClose: () => void
  onRequestTakePhoto: () => void
  onFetchLatestPhoto: () => void
}

export default function DevicePhotoDialog(props: Props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="md"
      sx={{ '& .MuiDialog-paper': { m: 0, width: '100%' } }}
      TransitionProps={{ onExited: props.onClose }}
    >
      <Box sx={{ padding: '18px' }}>
        <Stack spacing={2}>
          <Paper elevation={0} sx={{ width: '100%' }}>
            <Box
              component={'img'}
              src={props.photoData.url}
              sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Paper>
          <SlidePhoto deviceId={props.devicePhotoDialog.actcastId} />
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                loading={props.requestTakePhotoStatus.requesting}
                variant="contained"
                onClick={props.onRequestTakePhoto}
              >
                写真の更新を要求
              </LoadingButton>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="caption">最終更新: {props.photoData.taken_at}</Typography>
            </Box>
          </Stack>
        </Stack>
        <Box sx={{ position: 'absolute', left: 0, top: 0, margin: '32px' }}>
          <Dl>
            <Dt>Camera Name:</Dt>
            <Dd>{props.devicePhotoDialog.actcastId}</Dd>
            <Dt>Name:</Dt>
            <Dd>{props.devicePhotoDialog.name}</Dd>
            <Dt>Foundness:</Dt>
            <Dd>{props.devicePhotoDialog.foundness}</Dd>
          </Dl>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '32px',
            display: 'flex',
            columnGap: '8px'
          }}
        >
          <IconButton aria-label="reload" onClick={props.onFetchLatestPhoto}>
            <ReplayIcon />
          </IconButton>
          <IconButton aria-label="close" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  )
}
