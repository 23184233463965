import ReactDOM from 'react-dom/client'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { Auth0Provider } from '@auth0/auth0-react'
import { RecoilRoot } from 'recoil'
import { auth0 } from './constants'
import ThemeProvider from './ThemeProvider'
import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <RecoilRoot>
    <ThemeProvider>
      <Auth0Provider domain={auth0.domain} clientId={auth0.clientId} authorizationParams={auth0.authorizationParams}>
        <App />
      </Auth0Provider>
    </ThemeProvider>
  </RecoilRoot>
)
