import { Box, Paper, Typography } from '@mui/material'

export default function CenterCoordinateDisplay({ sx, latitude, longitude }) {
  return (
    <Box sx={{ ...sx }}>
      <Paper
        elevation={0}
        sx={{
          minWidth: '240px',
          backgroundColor: 'background.transparent',
          padding: '16px'
        }}
      >
        <Typography variant="caption" component="p">
          Latitude:{latitude}
        </Typography>
        <Typography variant="caption" component="p">
          Longitude:{longitude}
        </Typography>
      </Paper>
    </Box>
  )
}
