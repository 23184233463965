import { useLocalStorage } from '@rpgtec/use-storage'
import Box from '@mui/material/Box'
import Layout from './Layout'
import Map from '../../components/Map'
import Header from '../../components/Header'
import SideMenu from '../../components/SideMenu'
import ActcastDeviceGroups from './ActcastDeviceGroups'
import ToggleListButton from './ToggleListButton'
import DevicePhotoViewer from '../../components/DevicePhotoViewer/index'

export default function DashboardPage() {
  const [mode] = useLocalStorage('themeMode', 'light')

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Map key={mode} />
        <SideMenu />
      </Box>
      <Header
        sx={{ height: 60 }}
        menu={
          <>
            <ActcastDeviceGroups />
            <ToggleListButton />
          </>
        }
      />
      <DevicePhotoViewer />
    </Layout>
  )
}
