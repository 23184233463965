import { type PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import Header from '../../../components/Header'

export default function Layout(props: PropsWithChildren) {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        maxHeight: '100vh',
        overflow: 'hidden',
        display: 'grid',
        gridTemplateRows: '1fr 60px'
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '120px 1fr',
          height: 'calc(100vh - 60px)'
        }}
      >
        <Paper
          sx={{
            borderRadius: 0
          }}
        >
          <MenuList>
            <MenuItem component={Link} to={'/app/dashboard/analytics'}>
              デバイス
            </MenuItem>
            <MenuItem component={Link} to={'/app/dashboard/analytics/event'}>
              イベント
            </MenuItem>
          </MenuList>
        </Paper>
        {props.children}
      </Box>
      <Header sx={{ minHeight: 60 }} />
    </Box>
  )
}
