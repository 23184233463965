import { useStorage } from '@rpgtec/use-storage'
import Button from '@mui/material/Button'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

export default function ToggleListButton() {
  const [open, setOpen] = useStorage('SideMenuOpen', true)

  return (
    <Button
      color={open ? 'secondary' : 'primary'}
      variant="contained"
      size="small"
      sx={{ minWidth: 'auto' }}
      onClick={() => setOpen(!open)}
      endIcon={<FormatListBulletedIcon />}
    >
      {open ? 'リスト閉じる' : 'リスト表示'}
    </Button>
  )
}
