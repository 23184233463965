import { useMemo, useState } from 'react'
import { useStorage } from '@rpgtec/use-storage'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import DeviceList from './DeviceList'
import NotificationList from './NotificationList'
import Drawer from '@mui/material/Drawer'

export default function SideMenu() {
  const [tab, setTab] = useState('devices')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [open, setOpen] = useStorage('SideMenuOpen', false)
  const width = useMemo(() => (isMobile ? '100%' : '500px'), [isMobile])

  return (
    <Drawer
      variant={isMobile ? 'temporary' : 'persistent'}
      anchor={isMobile ? 'bottom' : 'right'}
      elevation={100}
      open={open}
      onClose={() => setOpen(false)}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ elevation: 16 }}
      sx={{
        width: open ? width : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          transition: 'width .1s linear',
          boxSizing: 'border-box',
          width: width,
          height: '100%',
          border: isMobile ? ({ palette }) => `4px solid ${palette.primary.main}` : 0,
          backgroundImage: 'none',
          overflow: 'hidden',
          bgcolor: 'background.sideMenu',
          borderLeft: ({ palette }) => `4px solid ${palette.primary.main}`
        }
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            height: 60,
            borderBottom: ({ palette }) => `1px solid ${palette.primary.main}`
          }}
        >
          <Tabs
            value={tab}
            onChange={(event, value) => setTab(value)}
            sx={{ '.MuiTab-root': { height: 60 }, '.MuiTabs-indicator': { height: 4 } }}
            variant="fullWidth"
          >
            <Tab value="devices" label="端末一覧" />
            <Tab value="notifications" label="通知" />
          </Tabs>
        </Box>
        <Box sx={{ flexGrow: 1, width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
          {tab === 'devices' && <DeviceList />}
          {tab === 'notifications' && <NotificationList />}
        </Box>
      </Box>
    </Drawer>
  )
}
