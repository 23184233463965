import { useActcast } from '../../hooks/useActcast'
import { useDeviceAndDeviceGroups } from '../../hooks/useDeviceAndDeviceGroups'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Button from '@mui/material/Button'
import DeviceListItem from './DeviceListItem'
import { Divider } from '@mui/material'

const LoadingDevices = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3
      }}
    >
      <CircularProgress size={20} />
    </Box>
  )
}

const ErrorDevices = () => {
  const { fetchDevicesFromCurrentGroups } = useDeviceAndDeviceGroups()

  return (
    <Box sx={{ p: 3 }}>
      <Button onClick={() => fetchDevicesFromCurrentGroups()}>端末取得</Button>
    </Box>
  )
}

function Device(props: { deviceId: string }) {
  const {
    state: { devices }
  } = useActcast()

  const device = devices.devices[props.deviceId]
  if (!device) {
    return <>loading...</>
  }

  return (
    <div>
      <DeviceListItem data={device} />
      <Divider variant="middle" />
    </div>
  )
}

export default function DeviceList() {
  const {
    state: { actcastDeviceGroups, devices }
  } = useActcast()

  if (!actcastDeviceGroups.initializedFlag || actcastDeviceGroups.loading || devices.loading) {
    return (
      <Box>
        <LoadingDevices />
      </Box>
    )
  }

  const deviceGroup = actcastDeviceGroups.actcastDeviceGroups.data[actcastDeviceGroups.currentActcastDeviceGroupId]
  const deviceIds = deviceGroup ? deviceGroup.actcastDevices : []

  return (
    <Box>
      {deviceIds.length === 0 ? (
        <Box sx={{ p: 3 }}>端末がありません</Box>
      ) : (
        deviceIds.map((id) => {
          return <Device key={id} deviceId={id} />
        })
      )}
    </Box>
  )
}
