import { useEffect, useMemo } from 'react'
import { type LatestPhotoType, useActcast } from '../../hooks/useActcast'
import { useDevicePhotoViewer } from '../../hooks/useDevicePhotoViewer'
import { useMediaQuery, useTheme } from '@mui/material'
import DevicePhotoDrawer from './DevicePhotoDrawer'
import DevicePhotoDialog from './DevicePhotoDialog'

export default function DevicePhotoViewer() {
  const {
    state: { latestPhotoMap, requestTakePhotoStatus },
    fetchLatestPhoto,
    requestTakePhoto
  } = useActcast()
  const {
    state: { open, devicePhotoDialog },
    closeDialog
  } = useDevicePhotoViewer()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (devicePhotoDialog.actcastId !== '') {
      fetchLatestPhoto(devicePhotoDialog.actcastId)
    }
  }, [devicePhotoDialog.actcastId, fetchLatestPhoto])

  const photoData: LatestPhotoType = useMemo(() => {
    if (devicePhotoDialog.actcastId === '') {
      return { url: '', taken_at: '' }
    }
    const _photoData = latestPhotoMap[devicePhotoDialog.actcastId]
    if (!_photoData) {
      return { url: '', taken_at: '' }
    }
    return _photoData
  }, [devicePhotoDialog, latestPhotoMap])

  const onClose = () => {
    closeDialog()
  }
  const onRequestTakePhoto = () => {
    requestTakePhoto(devicePhotoDialog.actcastId)
  }
  const onFetchLatestPhoto = () => fetchLatestPhoto(devicePhotoDialog.actcastId)

  return isMobile ? (
    <DevicePhotoDrawer
      photoData={photoData}
      requestTakePhotoStatus={requestTakePhotoStatus}
      devicePhotoDialog={devicePhotoDialog}
      onClose={onClose}
      onRequestTakePhoto={onRequestTakePhoto}
      onFetchLatestPhoto={onFetchLatestPhoto}
    />
  ) : (
    <DevicePhotoDialog
      open={open}
      photoData={photoData}
      requestTakePhotoStatus={requestTakePhotoStatus}
      devicePhotoDialog={devicePhotoDialog}
      onClose={onClose}
      onRequestTakePhoto={onRequestTakePhoto}
      onFetchLatestPhoto={onFetchLatestPhoto}
    />
  )
}
