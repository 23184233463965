import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { setLocalStorage } from '@rpgtec/use-storage'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import confirm from '../../lib/confirm'

export default function MenuButton() {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()

  const handleModeSetting = () => {
    setLocalStorage('themeMode', (x) => ({ dark: 'light', light: 'dark' }[x]))
    setAnchorEl(null)
  }

  const handleMap = () => {
    navigate('/app/dashboard')
    setAnchorEl(null)
  }

  const handleAnalytics = () => {
    navigate('/app/dashboard/analytics')
    setAnchorEl(null)
  }

  const handleLogout = async () => {
    if (await confirm()) {
      logout()
    }
  }

  return (
    <>
      <Button
        color="inherit"
        variant="outlined"
        size="small"
        sx={{ minWidth: 'auto' }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <MenuItem onClick={handleMap}>地図ダッシュボード</MenuItem>
        <MenuItem onClick={handleAnalytics}>Analyticsダッシュボード</MenuItem>
        <MenuItem onClick={handleModeSetting}>モード切替</MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
      </Menu>
    </>
  )
}
