import type { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import Box, { type BoxProps } from '@mui/material/Box'
import MenuButton from './MenuButton'

export default function Header({ menu, sx = {}, ...props }: { menu?: ReactNode } & BoxProps) {
  return (
    <Box
      sx={{
        height: 60,
        maxHeight: 60,
        display: 'flex',
        alignItems: 'center',
        px: 3,
        position: 'sticky',
        top: 0,
        zIndex: (theme) => theme.zIndex.drawer + 1,
        gap: 2,
        bgcolor: 'background.header',
        borderTop: '4px solid',
        borderColor: 'primary.main',
        ...sx
      }}
      {...props}
    >
      <Box
        component={Link}
        to="/"
        sx={{
          color: 'text.primary',
          textDecoration: 'none',
          fontWeight: 'bold',
          fontFamily: 'Noto Sans JP'
        }}
      >
        VISNU CLOUD
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      {menu}
      <MenuButton />
    </Box>
  )
}
