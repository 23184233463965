import { Box, Button } from '@mui/material'
import ThemeProvider from '../../ThemeProvider'

type Props = {
  data: { actcastId: string; name: string; foundness: string }
  openDialog: () => void
}

export default function MarkerPopupContent({ data, openDialog }: Props) {
  return (
    <ThemeProvider>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          dl: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            margin: '0px',
            dt: {
              flexBasis: '25%',
              paddingTop: '4px',
              paddingBottom: '4px'
            },
            dd: {
              flexBasis: '75%',
              marginInlineStart: '0px',
              paddingTop: '4px',
              paddingBottom: '4px'
            }
          }
        }}
      >
        <Box component={'dl'}>
          <dt>ID:</dt>
          <dd>{data.actcastId}</dd>
          <dt>Name:</dt>
          <dd>{data.name}</dd>
          <dt>Foundness:</dt>
          <dd>{data.foundness}</dd>
        </Box>
        <Button
          variant="contained"
          onClick={() => openDialog()}
          size="small"
          sx={{
            width: '120px',
            padding: '4px',
            marginTop: '8px'
          }}
        >
          Detail | 詳細
        </Button>
      </Box>
    </ThemeProvider>
  )
}
