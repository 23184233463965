import type { Devices } from './type'
import { useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Collapse from '@mui/material/Collapse'
import SpeedMeter from './SpeedMeter'
import Temperature from './Temperature'

export default function DeviceTable(props: { deviceIds: string[]; devices: Devices }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>id</TableCell>
            <TableCell>name</TableCell>
            <TableCell>hostname</TableCell>
            <TableCell>tags</TableCell>
            <TableCell>foundness</TableCell>
            <TableCell>モデルID</TableCell>
            <TableCell>モデル名</TableCell>
            <TableCell>CPU温度</TableCell>
            <TableCell>メモリ</TableCell>
            <TableCell>メモリ使用量</TableCell>
            <TableCell>ディスク</TableCell>
            <TableCell>ディスク使用量</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.deviceIds.map((id) => {
            const device = props.devices.devices[id]
            if (!device) {
              return <TableRow>loading...</TableRow>
            }
            return <Row key={id} device={device} />
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Row({ device }: { device: Devices['devices'][string] | undefined }) {
  const [open, setOpen] = useState(!!device?.status)
  const cpuTemperature = device.status ? Math.floor(device.status?.cpu_temperature * 10) / 10 : null
  const memoryUsagePercent = device.status ? Math.ceil((device.status.memory_usage / device.status.memory) * 100) : null
  const diskUsagePercent = device.status ? Math.ceil((device.status.disk_usage / device.status.disk) * 100) : null

  return (
    <>
      <TableRow key={device._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{device._id}</TableCell>
        <TableCell>{device.name}</TableCell>
        <TableCell>{device.hostname}</TableCell>
        <TableCell>{device.tags.join(',')}</TableCell>
        <TableCell>{device.foundness}</TableCell>
        <TableCell>{device.act.id}</TableCell>
        <TableCell>{device.act.name}</TableCell>
        <TableCell>{cpuTemperature ? `${cpuTemperature}°` : 'unknown'}</TableCell>
        <ByteDataCell value={device.status?.memory ?? null} />
        <ByteDataCell value={device.status?.memory_usage ?? null} />
        <ByteDataCell value={device.status?.disk ?? null} />
        <ByteDataCell value={device.status?.disk_usage ?? null} />
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open}>
            <Stack direction="row" sx={{ mt: 2 }}>
              {cpuTemperature && (
                <Box sx={{ flex: 1 }}>
                  <Temperature title="CPU温度" data={[{ value: cpuTemperature }]} />
                </Box>
              )}
              {memoryUsagePercent && (
                <Box sx={{ flex: 1 }}>
                  <SpeedMeter title="メモリ使用率" data={[{ value: memoryUsagePercent }]} />
                </Box>
              )}
              {diskUsagePercent && (
                <Box sx={{ flex: 1 }}>
                  <SpeedMeter title="ディスク使用率" data={[{ value: diskUsagePercent }]} />
                </Box>
              )}
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

function ByteDataCell(props: { value: number | null }) {
  const ceil = (num: number) => Math.ceil(num * 100) / 100

  const value = (() => {
    if (!props.value) {
      return 'unknown'
    }
    if (props.value >= Math.pow(1024, 3)) {
      return `${ceil(props.value / Math.pow(1024, 3))}GB`
    } else if (props.value >= Math.pow(1024, 2)) {
      return `${ceil(props.value / Math.pow(1024, 2))}MB`
    } else if (props.value >= 1024) {
      return `${ceil(props.value / 1024)}KB`
    }
    return `${props.value}B`
  })()

  return <TableCell>{value}</TableCell>
}
