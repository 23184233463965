import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Layout from '../Layout'
import DeviceTable from './DeviceTable'
import PieChart from '../PieChart'
import ActcastDeviceGroupMenu from '../ActcastDeviceGroupMenu'
import { useAnalytics } from '../../../../hooks/useAnalytics'

export default function AnalyticsDashboardPage() {
  const {
    state: { actcastDeviceGroups, devices },
    setCurrentActcastDeviceGroupId
  } = useAnalytics()

  const deviceNum = actcastDeviceGroups.actcastDeviceGroups.ids.reduce((acc, id) => {
    const group = actcastDeviceGroups.actcastDeviceGroups.data[id]
    return acc + group.actcastDevices.length
  }, 0)

  const currentGroup = actcastDeviceGroups.actcastDeviceGroups.data[actcastDeviceGroups.currentActcastDeviceGroupId]

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          maxHeight: '100%',
          minHeight: '100%',
          p: 2
        }}
      >
        <Box>
          <Stack direction="row" gap={2}>
            <Stack gap={2}>
              <NumberCard title="デバイスグループ数" num={actcastDeviceGroups.actcastDeviceGroups.ids.length} />
              <NumberCard title="デバイス総数" num={deviceNum} />
            </Stack>
            <DeviceGroupPieChart />
          </Stack>
        </Box>
        <Paper sx={{ p: 2, mt: 2 }}>
          <ActcastDeviceGroupMenu
            actcastDeviceGroups={actcastDeviceGroups}
            onChange={(groupId) => setCurrentActcastDeviceGroupId(groupId)}
          />
        </Paper>
        <Box>
          <Paper sx={{ mt: 2 }}>
            {currentGroup ? <DeviceTable deviceIds={currentGroup.actcastDevices} devices={devices} /> : <>loading...</>}
          </Paper>
        </Box>
      </Box>
    </Layout>
  )
}

function NumberCard(props: { num: number; title: string }) {
  return (
    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="caption">{props.title}</Typography>
      <Typography variant="h2" sx={{ color: 'text.primary' }}>
        {props.num}
      </Typography>
    </Paper>
  )
}

function DeviceGroupPieChart() {
  const {
    state: { actcastDeviceGroups }
  } = useAnalytics()

  const data = actcastDeviceGroups.actcastDeviceGroups.ids.map((id) => {
    const group = actcastDeviceGroups.actcastDeviceGroups.data[id]
    return { name: group.name, value: group.actcastDevices.length }
  })

  return (
    <Paper sx={{ flex: 1 }}>
      <PieChart title="" data={data} />
    </Paper>
  )
}
