import type { PropsWithChildren } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import PieChart from '../PieChart'
import BarChart from './BarChart'
import AgeGenderBarChart from './AgeGenderBarChart'
import ActcastDeviceGroupMenu from '../ActcastDeviceGroupMenu'
import Layout from '../Layout'
import { useEventAnalytics } from './index.hooks'

type Events = ReturnType<typeof useEventAnalytics>['state']['events']
type Devices = ReturnType<typeof useEventAnalytics>['state']['devices']

export default function EventDashboardPage() {
  const {
    state: { actcastDeviceGroups, devices, events },
    setCurrentActcastDeviceGroupId
  } = useEventAnalytics()

  const currentGroup = actcastDeviceGroups.actcastDeviceGroups.data[actcastDeviceGroups.currentActcastDeviceGroupId]

  return (
    <Layout>
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', overflowY: 'scroll', height: '100%', p: 2 }}>
        <Paper sx={{ p: 2 }}>
          <ActcastDeviceGroupMenu
            actcastDeviceGroups={actcastDeviceGroups}
            onChange={(groupId) => setCurrentActcastDeviceGroupId(groupId)}
          />
        </Paper>
        {currentGroup ? (
          <Table deviceIds={currentGroup.actcastDevices} devices={devices} events={events} />
        ) : (
          <>loading...</>
        )}
      </Box>
    </Layout>
  )
}

function Table(props: { deviceIds: string[]; devices: Devices; events: Events }) {
  if (props.deviceIds.length <= 0) {
    return <div>表示できるイベントがありません</div>
  }

  return (
    <Stack direction="column" gap={2} sx={{ mt: 2, flex: 1, flexDirection: 'column' }}>
      {props.deviceIds.map((id) => {
        const device = props.devices.devices[id]
        const event = props.events[id]
        return <DeviceCard key={id} device={device} event={event} />
      })}
    </Stack>
  )
}

function DeviceCard(props: { device: Devices['devices'][string] | undefined; event: Events[string] | undefined }) {
  const { event, device } = props
  const name = device?.name ?? ''

  if (event && event.type === 9249) {
    return (
      <Paper sx={{ flex: 1, p: 2 }}>
        <Typography>{name}</Typography>
        <Stack>
          <Stack direction="row" gap={2}>
            <NumberCard title="総人数">{event.allCount.count}人</NumberCard>
            <NumberCard title="平均年齢">{Math.floor(event.age.average * 10) / 10}歳</NumberCard>
          </Stack>
          <Stack direction="row">
            <GenderPieChart gender={event.gender} />
            <AgePieChart age={event.age} />
            <AgeGenderPieChart age={event.age} />
          </Stack>
          <Stack direction="row">
            <AgeBarBart age={event.age} />
            <AgeGender age={event.age} />
          </Stack>
        </Stack>
      </Paper>
    )
  }

  return (
    <Paper sx={{ flex: 1, p: 2 }}>
      <Typography>{name}</Typography>
      <Typography variant="caption">可視化できないモデルです</Typography>
    </Paper>
  )
}

function NumberCard(props: PropsWithChildren<{ title: string }>) {
  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <Typography variant="subtitle2">{props.title}</Typography>
      <Typography variant="h3" sx={{ color: 'text.primary' }}>
        {props.children}
      </Typography>
    </Box>
  )
}

type Event9249 = Extract<Events[string], { type: 9249 }>
function AgePieChart({ age }: { age: Event9249['age'] }) {
  const data = [
    {
      name: '0-9歳',
      value: age.range['0_9'].count
    },
    {
      name: '10-19歳',
      value: age.range['10_19'].count
    },
    {
      name: '20-29歳',
      value: age.range['20_29'].count
    },
    {
      name: '30-39歳',
      value: age.range['30_39'].count
    },
    {
      name: '40-49歳',
      value: age.range['40_49'].count
    },
    {
      name: '50-59歳',
      value: age.range['50_59'].count
    },
    {
      name: '60-69歳',
      value: age.range['60_69'].count
    },
    {
      name: '70-79歳',
      value: age.range['70_79'].count
    },
    {
      name: '80歳以上',
      value: age.range['80_'].count
    }
  ]

  return <PieChart title="年代分布" data={data} />
}

function AgeBarBart({ age }: { age: Event9249['age'] }) {
  const data = [
    {
      name: '0-9歳',
      value: age.range['0_9'].count
    },
    {
      name: '10-19歳',
      value: age.range['10_19'].count
    },
    {
      name: '20-29歳',
      value: age.range['20_29'].count
    },
    {
      name: '30-39歳',
      value: age.range['30_39'].count
    },
    {
      name: '40-49歳',
      value: age.range['40_49'].count
    },
    {
      name: '50-59歳',
      value: age.range['50_59'].count
    },
    {
      name: '60-69歳',
      value: age.range['60_69'].count
    },
    {
      name: '70-79歳',
      value: age.range['70_79'].count
    },
    {
      name: '80歳以上',
      value: age.range['80_'].count
    }
  ]
  return <BarChart title="年代分布" data={data} />
}

function AgeGenderPieChart({ age }: { age: Event9249['age'] }) {
  const data = [
    {
      name: 'F: 0-9歳',
      value: age.range['0_9'].gender['Female']
    },
    {
      name: 'M: 0-9歳',
      value: age.range['0_9'].gender['Male']
    },
    {
      name: 'F: 10-19歳',
      value: age.range['10_19'].gender['Female']
    },
    {
      name: 'M: 10-19歳',
      value: age.range['10_19'].gender['Male']
    },
    {
      name: 'F: 20-29歳',
      value: age.range['20_29'].gender['Female']
    },
    {
      name: 'M: 20-29歳',
      value: age.range['20_29'].gender['Male']
    },
    {
      name: 'F: 30-39歳',
      value: age.range['30_39'].gender['Female']
    },
    {
      name: 'M: 30-39歳',
      value: age.range['30_39'].gender['Male']
    },
    {
      name: 'F: 40-49歳',
      value: age.range['40_49'].gender['Female']
    },
    {
      name: 'M: 40-49歳',
      value: age.range['40_49'].gender['Male']
    },
    {
      name: 'F: 50-59歳',
      value: age.range['50_59'].gender['Female']
    },
    {
      name: 'M: 50-59歳',
      value: age.range['50_59'].gender['Male']
    },
    {
      name: 'F: 60-69歳',
      value: age.range['60_69'].gender['Female']
    },
    {
      name: 'M: 60-69歳',
      value: age.range['60_69'].gender['Male']
    },
    {
      name: 'F: 70-79歳',
      value: age.range['70_79'].gender['Female']
    },
    {
      name: 'M: 70-79歳',
      value: age.range['70_79'].gender['Male']
    },
    {
      name: 'F: 80歳以上',
      value: age.range['80_'].gender['Female']
    },
    {
      name: 'M: 80歳以上',
      value: age.range['80_'].gender['Male']
    }
  ]

  return <PieChart title="年代ごとの性別分布" data={data} />
}

function AgeGender({ age }: { age: Event9249['age'] }) {
  const category = ['0-9歳', '10-19歳', '20-29歳', '30-39歳', '40-49歳', '50-59歳', '60-69歳', '70-79歳', '80歳以上']
  const data = [
    {
      name: 'Male',
      data: [
        age.range['0_9'].gender['Male'],
        age.range['10_19'].gender['Male'],
        age.range['20_29'].gender['Male'],
        age.range['30_39'].gender['Male'],
        age.range['40_49'].gender['Male'],
        age.range['50_59'].gender['Male'],
        age.range['60_69'].gender['Male'],
        age.range['70_79'].gender['Male'],
        age.range['80_'].gender['Male']
      ]
    },
    {
      name: 'Female',
      data: [
        age.range['0_9'].gender['Female'],
        age.range['10_19'].gender['Female'],
        age.range['20_29'].gender['Female'],
        age.range['30_39'].gender['Female'],
        age.range['40_49'].gender['Female'],
        age.range['50_59'].gender['Female'],
        age.range['60_69'].gender['Female'],
        age.range['70_79'].gender['Female'],
        age.range['80_'].gender['Female']
      ]
    }
  ]

  return <AgeGenderBarChart title="年代ごとの性別分布" legend={['Male', 'Female']} category={category} data={data} />
}

function GenderPieChart(props: { gender: Event9249['gender'] }) {
  const data = props.gender.map((g) => {
    return { name: g._id, value: g.count }
  })

  return <PieChart title="男女比" data={data} />
}
