import { clients } from '../../lib/clients'
import { atom, useRecoilState } from 'recoil'

type Device = Awaited<
  ReturnType<(typeof clients)['/api/actcastDeviceGroups/:groupId/devices']['GET']['client']>
>['body']['actcastDevices'][number]

type DeviceState = {
  loading: boolean
  devices: {
    [key: string]: Device
  }
}

export const deviceState = atom<DeviceState>({
  key: 'actcast:deviceState',
  default: {
    loading: false,
    devices: {}
  }
})

export const useFetchDevicesFromGroupsBase = () => {
  const [devices, setDevices] = useRecoilState(deviceState)

  const fetchDevicesFromGroupsBase = async (options: { actcastDeviceGroupId: string; accessToken: string }) => {
    if (!options.actcastDeviceGroupId) {
      return
    }
    setDevices({ ...devices, loading: true })
    try {
      if (!options.accessToken) {
        return
      }
      const res = await clients['/api/actcastDeviceGroups/:groupId/devices'].GET.client({
        headers: {
          Authorization: `Bearer ${options.accessToken}`
        },
        params: {
          groupId: options.actcastDeviceGroupId
        }
      })
      const newDevices = { ...devices.devices }
      for (const device of res.body.actcastDevices) {
        newDevices[device._id] = device
      }
      setDevices({ ...devices, loading: false, devices: newDevices })
      return { data: res.body }
    } catch (e) {
      setDevices({ ...devices, loading: false })
    }
  }

  return { devices, fetchDevicesFromGroupsBase }
}
