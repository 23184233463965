import Box from '@mui/material/Box'

function Border({ sx, ...props }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-10vmax',
        bottom: '-10vmax',
        width: '10vmax',
        bgcolor: '#313131',
        transform: 'rotate(10deg)',
        ...sx
      }}
      {...props}
    />
  )
}

export default function Background() {
  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: -1,
        pointerEvents: 'none',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        bgcolor: '#2A2A2A'
      }}
    >
      <Border sx={{ right: '-5vmax' }} />
      <Border sx={{ right: '10vmax', bgcolor: '#413025' }} />
      <Border sx={{ right: '25vmax' }} />
      <Border sx={{ right: '40vmax' }} />
      <Border sx={{ right: '55vmax' }} />
      <Border sx={{ right: '70vmax' }} />
      <Border sx={{ right: '85vmax' }} />
      <Border sx={{ right: '100vmax' }} />
    </Box>
  )
}
