import { useAuth0 } from '@auth0/auth0-react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { Loading } from '../../components/Loading'
import Background from './Background'
import { useDeviceAndDeviceGroups } from '../../hooks/useDeviceAndDeviceGroups'

export default function TopPage() {
  const { isAuthenticated } = useAuth0()
  const { isDevicesLoading, isActcastDeviceGroupLoading } = useDeviceAndDeviceGroups()

  return (
    <Box>
      <Background />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          my: 4,
          gap: 4,
          color: '#fff'
        }}
      >
        <Box sx={{ my: 8, textAlign: 'center' }}>
          <Box component="img" src="/logo.png" sx={{ width: 210 }} />
          <Box
            sx={{
              fontSize: 24,
              fontFamily: '"Noto Sans JP"',
              fontWeight: '900',
              color: '#707070',
              mt: 3
            }}
          >
            <Box>すべての人が、</Box>
            <Box>いつも通りに、</Box>
            <Box>暮らせる世界を</Box>
          </Box>
        </Box>
        <Box>
          {isAuthenticated && <WarningMessage loading={isDevicesLoading || isActcastDeviceGroupLoading} />}
          {!isAuthenticated && <StartSurviceButton />}
        </Box>
      </Box>
      <Box
        sx={{
          height: 150,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 8
        }}
      >
        <Box component="img" src="/logo.png" sx={{ height: 36 }} />
        <Box sx={{ color: '#fff', mt: 4 }}>&copy; {new Date().getFullYear()} Visnu Inc.</Box>
      </Box>
    </Box>
  )
}

function WarningMessage(props: { loading: boolean }) {
  const { logout } = useAuth0()

  if (props.loading) {
    return (
      <Box>
        <Stack justifyContent="center" spacing={1}>
          <Box>データ取得中...</Box>
          <Stack justifyContent="center" alignItems="center">
            <Loading />
          </Stack>
        </Stack>
        <Button sx={{ mt: 4 }} size="large" variant="contained" onClick={() => logout()}>
          ログアウト
        </Button>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Box>まだ表示するデバイスがありません</Box>
      <Box>管理者にご連絡ください</Box>
      <Button sx={{ mt: 4 }} size="large" variant="contained" onClick={() => logout()}>
        ログアウト
      </Button>
    </Box>
  )
}

function StartSurviceButton() {
  const { loginWithRedirect } = useAuth0()

  return (
    <Button size="large" variant="contained" onClick={() => loginWithRedirect()}>
      サービスを利用する
    </Button>
  )
}
