import { useState, useEffect } from 'react'
import { useStorage, setStorage } from '@rpgtec/use-storage'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'

const key = `notify-${Date.now()}`

export default function notify(message) {
  setStorage(key, message)
}

export function Notify({ autoHideDuration = 5000, ...props }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useStorage(key, '')

  useEffect(() => {
    const open = Boolean(message)
    setOpen(open)

    if (open) {
      const tid = setTimeout(() => setOpen(false), autoHideDuration)
      return () => clearTimeout(tid)
    }
  }, [message, autoHideDuration])

  return (
    <Snackbar
      message={message}
      open={open}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={Slide}
      TransitionProps={{
        /* direction: 'down', */
        onExited: () => setMessage('')
      }}
      {...props}
    />
  )
}
