import type { FC, PropsWithChildren } from 'react'
import type { LatestPhotoType, RequestTakePhotoStatus } from '../../hooks/useActcast'
import { Drawer, Box, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ReplayIcon from '@mui/icons-material/Replay'
import { LoadingButton } from '@mui/lab'
import SlidePhoto from './SlidePhoto'
import { useDevicePhotoViewer, type DevicePhotoDialogData } from '../../hooks/useDevicePhotoViewer'

const Dl: FC<PropsWithChildren> = (props) => {
  return (
    <Typography
      variant="caption"
      component={'dl'}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        margin: '0px'
      }}
    >
      {props.children}
    </Typography>
  )
}

const MDl = Dl

const Dt: FC<PropsWithChildren> = (props) => {
  return (
    <Typography
      variant="caption"
      component={'dt'}
      sx={{
        flexBasis: '25%',
        paddingTop: '4px',
        paddingBottom: '4px'
      }}
    >
      {props.children}
    </Typography>
  )
}

const MDt = Dt

const Dd: FC<PropsWithChildren> = (props) => {
  return (
    <Typography
      variant="caption"
      component={'dd'}
      sx={{
        flexBasis: '75%',
        marginInlineStart: '0px',
        paddingTop: '4px',
        paddingBottom: '4px'
      }}
    >
      {props.children}
    </Typography>
  )
}
const MDd = Dd

type Props = {
  photoData: LatestPhotoType
  requestTakePhotoStatus: RequestTakePhotoStatus
  devicePhotoDialog: DevicePhotoDialogData
  onClose: () => void
  onRequestTakePhoto: () => void
  onFetchLatestPhoto: () => void
}

export default function DevicePhotoViewer(props: Props) {
  const theme = useTheme()
  const {
    state: { open }
  } = useDevicePhotoViewer()

  return (
    <Drawer anchor={'bottom'} open={open} onClose={props.onClose}>
      <Box
        sx={{
          padding: '8px',
          backgroundColor: theme.palette.background.default
        }}
      >
        <Stack spacing={0}>
          <Paper elevation={0} sx={{ width: '100%' }}>
            <Box
              component={'img'}
              src={props.photoData.url}
              sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </Paper>
          <SlidePhoto deviceId={props.devicePhotoDialog.actcastId} />
          <Stack spacing={0}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography variant="caption">最終更新: {props.photoData.taken_at}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <LoadingButton
                loading={props.requestTakePhotoStatus.requesting}
                variant="contained"
                size="small"
                onClick={props.onRequestTakePhoto}
              >
                写真の更新を要求
              </LoadingButton>
            </Box>
          </Stack>
          <Box sx={{ paddingBottom: '10px' }}>
            <MDl>
              <MDt>ID:</MDt>
              <MDd>{props.devicePhotoDialog.actcastId}</MDd>
              <MDt>Name:</MDt>
              <MDd>{props.devicePhotoDialog.name}</MDd>
              <MDt>Foundness:</MDt>
              <MDd>{props.devicePhotoDialog.foundness}</MDd>
            </MDl>
          </Box>
        </Stack>
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            margin: '10px',
            display: 'flex',
            columnGap: '4px'
          }}
        >
          <IconButton aria-label="reload" onClick={props.onFetchLatestPhoto}>
            <ReplayIcon />
          </IconButton>
          <IconButton aria-label="close" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  )
}
