import { useRecoilState } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import { actcastDeviceGroupsState } from './recoil/deviceGrups'
import { useFetchDevicesFromGroupsBase } from './recoil/devices'
import { getAPIAccessToken } from '../lib/auth0'

export const useDeviceAndDeviceGroups = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [actcastDeviceGroups, setActcastDeviceGroups] = useRecoilState(actcastDeviceGroupsState)
  const { devices, fetchDevicesFromGroupsBase } = useFetchDevicesFromGroupsBase()

  const fetchDevicesFromGroups = async (actcastDeviceGroupId: string) => {
    const accessToken = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
    await fetchDevicesFromGroupsBase({ actcastDeviceGroupId, accessToken })
  }

  const setCurrentActcastDeviceGroupId = async (setCurrentId: string) => {
    const checkExistsId = (id: string) => {
      return actcastDeviceGroups.actcastDeviceGroups.ids.includes(id)
    }
    const currentActcastDeviceGroupId = checkExistsId(setCurrentId) ? setCurrentId : actcastDeviceGroups[0]._id
    setActcastDeviceGroups((current) => {
      return { ...current, currentActcastDeviceGroupId }
    })
    await fetchDevicesFromGroups(currentActcastDeviceGroupId)
  }

  return {
    currentActcastDeviceGroupId: actcastDeviceGroups.currentActcastDeviceGroupId,
    actcastDeviceGroups: actcastDeviceGroups.actcastDeviceGroups,
    isActcastDeviceGroupLoading: actcastDeviceGroups.loading,
    initializedActcastDevceGroup: actcastDeviceGroups.initializedFlag,
    isDevicesLoading: devices.loading,
    setCurrentActcastDeviceGroupId,
    fetchDevicesFromCurrentGroups: () => fetchDevicesFromGroups(actcastDeviceGroups.currentActcastDeviceGroupId)
  }
}
